import * as bartio from '@/assets/data/contracts/bartio.json';
import { Pools } from '@/types/pools';

/*
 * FX Pool Factories
 *
 * @returns {Record<string, string>} - A record of quote token addresses to their corresponding FXPoolDeployer addresses.
 *
 * !Important: The FxPoolDeployer address must be in lower case.
 */
const fxPoolFactories = {
  // NECT
  '0xf5afcf50006944d17226978e594d4d25f4f92b40':
    '0x33c608b9e7ae1877dcb665bd3d2d3bb327b01156',
  // hETH
  '0x501dbf23c2b004d751496adc073da3727c5fe80f':
    '0x39da60365de955d03c6c1ab7b80e8a4c458fa87e',
  // hSOL
  '0x482c38cd33e79a3e3c1cce792a72d41bafffd416':
    '0xb468a7ac04db7b07d8d2a8dec3e6008397ec3e4d',
  // hTIA
  '0x3db20aefcd234465c981b87da025711c91add2c3':
    '0xc3adc5347663c4fb93ad5029b6382a9f7fe73b05',
  // HONEY
  '0x0e4aaf1351de4c0264c5c7056ef3777b41bd8e03':
    '0x8e826703b6d471732415abd4a1e724a3bf451511',
  // USDC
  '0xd6d83af58a19cd14ef3cf6fe848c9a4d21e5727c':
    '0x8520b4ed7e7e54343ade583e6a7864718535eca9',
};

const pools: Pools = {
  IdsMap: {},
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: false,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x4dff2ceaf65cac188a8d481f34f3be9cd3d54d6c000000000000000000000007',
    '0x143ab24a1562bfec7a0695dde552e1ee5daace1b000200000000000000000001',
    '0xdda245377ce251114929beefcc8bbb6793e22cbd000000000000000000000003',
    '0xecaa8ee11396d52421a1388d229dffb85bf4c3f2000200000000000000000002',
    '0xd6671d907b7f791de7d9f89fba1a04d55d669a8300000000000000000000000c',
    '0x86a27e136ca7693dd743579efcff687a1afb91c1000200000000000000000016',
    '0x6827948144dc5198ec3cb329f62b695317cdadb4000200000000000000000017',
  ],
  IncludedPoolTypes: [
    'Weighted',
    // 'Stable',
    // 'MetaStable',
    // 'LiquidityBootstrapping',
    // 'Investment',
    // 'StablePhantom',
    'ComposableStable',
    'FX',
    // 'EulerLinear',
    // 'Gyro2',
    // 'Gyro3',
    // 'GyroE',
  ],
  Stable: {
    AllowList: [],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [],
  },
  Factories: {
    [bartio.WeightedPoolFactory]: 'weightedPool',
    [bartio.ComposableStablePoolFactory]: 'composableStablePool',
    // append fxPoolFactories to the factories
    ...Object.fromEntries(
      Object.values(fxPoolFactories).map(factory => [factory, 'fx'])
    ),
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [
      '0xf74a682b45f488df08a77dc6af07364e94e4ed98000000000000000000000005',
      '0x39fca0a506d01ff9cb727fe8edf088e10f6b431a000200000000000000000004',
    ],
    BurrMultiplier: {
      '0xf74a682b45f488df08a77dc6af07364e94e4ed98000000000000000000000005': 2,
      '0x39fca0a506d01ff9cb727fe8edf088e10f6b431a000200000000000000000004': 1.5,
    },
  },
  Metadata: {},
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [], // may contain pool ids with security issues
  NewVersionAvailable: {},
  Deprecated: {}, // for pool migration?
  GaugeMigration: {},
  BrandedRedirect: {},
  ExitViaInternalBalance: [],
  Issues: {},
  Curated: [
    '0x7a4ffb600c1566ec7a864db7ba6c40f166ef930e000200000000000000000009',
    '0x6e554d0b68a7f0b9232f35fed6b59da3624c77d000020000000000000000000a',
    '0x5dd67a2c9fba891c6780da434f4b9217c6cd41b600020000000000000000000b',
    '0x15204b95373bbbd3823a94d7e001c340b80c539b000100000000000000000008',
    '0x39fca0a506d01ff9cb727fe8edf088e10f6b431a000200000000000000000004',
    '0xf74a682b45f488df08a77dc6af07364e94e4ed98000000000000000000000005',
    '0xa220701d58d07dbc635b3344089aa46430a15570000100000000000000000006',
    '0x6acbbedecd914de8295428b4ee51626a1908bb12000000000000000000000010',
  ],
  FXPoolFactories: fxPoolFactories,
};

export default pools;
