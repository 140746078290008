import { TokenListURLMap } from '@/types/TokenList';

const tokenlists: TokenListURLMap = {
  BurrBear: {
    Allowlisted:
      'https://raw.githubusercontent.com/burrbear-dev/default-lists/refs/heads/main/src/tokens/mainnet/defaultTokenList.json',
  },
  External: [],
};
export default tokenlists;
