import { Config } from '../types';
import contracts from './contracts';
import keys from './keys';
import pools from './pools';
import rateProviders from './rateProviders';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '80084',
  chainId: 80084,
  chainName: 'Bartio',
  name: 'Bartio',
  shortName: 'Bartio',
  monorepoName: 'Bartio',
  slug: 'bartio',
  network: 'bartio',
  trustWalletNetwork: 'bartio',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  publicRpc: `https://bartio.rpc.berachain.com`,
  rpc: `https://bartio.rpc.berachain.com`,
  ws: ``,
  explorer: 'https://bartio.beratrail.io',
  explorerName: 'Beratrail',
  subgraph:
    'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/bartio/0.0.2/gn',
  poolsUrlV2:
    'https://storageapi.fleek.co/johngrantuk-team-bucket/poolsV2.json',
  subgraphs: {
    main: [
      'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/bartio/0.0.2/gn',
    ],
    aave: '',
    gauge: '',
    blocks: '',
    ammRewards:
      'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/burr-rewards-subgraph-bartio/beta/gn',
  },
  bridgeUrl: '',
  supportsEIP1559: true,
  supportsElementPools: false, // hides featured ecosystems section under pool list
  blockTime: 12,
  nativeAsset: {
    name: 'BERA',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'BERA',
    decimals: 18,
    deeplinkId: 'bera',
    logoURI: 'tokens/eth.png',
    minTransactionBuffer: '0.05',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'artio',
      platformId: 'artio',
    },
    apyVision: {
      networkName: '',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 2,
    weight: 100,
  },
  tokenlists,
  rateProviders,
  ammRewards: '0x6E125723D5d28ADb922d326EC04eA953B523A9da',
};

export default config;
