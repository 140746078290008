import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  Popular: {
    Symbols: ['XSGD', 'USDC'],
  },
  InitialSwapTokens: {
    input: '0x7A73FA0Be231B44dbcA23E98F49CAe7F11f367Ba', // XSGD
    output: '0xf3585984B956f2A26694ee1BAede7eAB658A5e7E', // USDC
  },
  Addresses: {
    nativeAsset: '0x0000000000000000000000000000000000000000', // BERA
    wNativeAsset: '0x7507c1dc16935B82698e4C63f2746A2fCf994dF8', // WBERA
    WETH: '0xE28AfD8c634946833e89ee3F122C06d7C537E8A8',
    BAL: '0x1Bc1A92fcDf8Df49d6E2B62f62a7ccf665c9d9A7',
    NECT: '0xf5AFCF50006944d17226978e594D4D25f4f92B40',
  },
  Permissionless: {
    baseTokens: [
      {
        token: '0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03', // HONEY
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67', // USDC:USD
      },
      {
        token: '0xbd61664a58b4d6d6ed0743e110f381b13ae65830', // rUSD
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67', // USDC:USD
      },
      {
        token: '0xbadc512c65507ce9775a68ea7664bc85ed9175f7', // brONEY
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67', // USDC:USD
      },
      {
        token: '0xf2c66a18c2730348be5e35e2bb37d4693d54c8a6', // USDI
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67', // USDC:USD
      },
      {
        token: '0x3c27c17bf6c2f9e2d1041c8eefcabd9c332afee3', // shETH
        oracle: '0xF5D1b5F80dC60ee43c2affdF99B7acC1e6f8e884', // ETH:USD
      },
      {
        token: '0x3176d5566350a92eb5f0283a4c137746694f03ee', // shSOL
        oracle: '0x6720b5F572C5eaC792F25A1DBb570a51d46B73fc', // SOL:USD
      },
      {
        token: '0x47256dbb5c91917e87a26b2020899256c4e1f1ad', // shTIA
        oracle: '0x84b1C010F8057Cc5B43E19a73AD0d75e60647D5f', // TIA:USD
      },
    ],
    quoteTokens: [
      {
        token: '0xf5AFCF50006944d17226978e594D4D25f4f92B40', // NECT
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67', // USDC:USD
      },
      {
        token: '0x501dbf23c2b004d751496adc073da3727c5fe80f', // hETH
        oracle: '0xF5D1b5F80dC60ee43c2affdF99B7acC1e6f8e884', // ETH:USD
      },
      {
        token: '0x482c38cd33e79a3e3c1cce792a72d41bafffd416', // hSOL
        oracle: '0x6720b5F572C5eaC792F25A1DBb570a51d46B73fc', // SOL:USD
      },
      {
        token: '0x3db20aefcd234465c981b87da025711c91add2c3', // hTIA
        oracle: '0x84b1C010F8057Cc5B43E19a73AD0d75e60647D5f', // TIA:USD
      },
      {
        token: '0x0E4aaF1351de4c0264C5c7056Ef3777b41BD8e03', // HONEY
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67',
      },
      {
        token: '0xd6D83aF58a19Cd14eF3CF6fe848C9A4d21e5727c', // USDC
        oracle: '0xc85F993de85896B521701B42299df1103A4d4D67',
      },
    ],
  },
};

export default tokens;
