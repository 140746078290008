import { Config } from '../types';
import contracts from './contracts';
import keys from './keys';
import pools from './pools';
import rateProviders from './rateProviders';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '80094',
  chainId: 80094,
  chainName: 'Berachain',
  name: 'Berachain',
  shortName: 'Berachain',
  monorepoName: 'Berachain',
  slug: 'berachain',
  network: 'berachain',
  trustWalletNetwork: 'berachain',
  unknown: false,
  visibleInUI: true,
  testNetwork: false,
  publicRpc: `https://rpc.berachain.com/`,
  rpc: `https://rpc.berachain.com/`,
  ws: ``,
  explorer: 'https://berascan.com',
  explorerName: 'Beratrail',
  subgraph:
    'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/berachain/prod/gn',
  poolsUrlV2:
    'https://storageapi.fleek.co/johngrantuk-team-bucket/poolsV2.json',
  subgraphs: {
    main: [
      'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/berachain/prod/gn',
    ],
    aave: '',
    gauge: '',
    blocks: '',
    ammRewards:
      'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/burr-rewards-subgraph/0.0.7/gn',
  },
  bridgeUrl: '',
  supportsEIP1559: true,
  supportsElementPools: false, // hides featured ecosystems section under pool list
  blockTime: 3,
  nativeAsset: {
    name: 'BERA',
    address: '0x0000000000000000000000000000000000000000',
    symbol: 'BERA',
    decimals: 18,
    deeplinkId: 'bera',
    logoURI: 'tokens/bera.png',
    minTransactionBuffer: '0.05',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'berachain',
      platformId: 'berachain',
    },
    apyVision: {
      networkName: '',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 2,
    weight: 100,
  },
  tokenlists,
  rateProviders,
};

export default config;
