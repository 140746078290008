import * as berachain from '@/assets/data/contracts/berachain.json';
import { Protocol } from '@/composables/useProtocols';
import { Pools } from '@/types/pools';

/*
 * FX Pool Factories
 *
 * @returns {Record<string, string>} - A record of quote token addresses to their corresponding FXPoolDeployer addresses.
 *
 * !Important: The FxPoolDeployer address must be in lower case.
 */
const fxPoolFactories = {
  // USDC.e
  '0x549943e04f40284185054145c6e4e9568c1d3241':
    '0x49c2859b4bd8b7fc1f79fb037b16815983c26ce7',
};

const pools: Pools = {
  IdsMap: {},
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: false,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x0b3ce9a5d7403f9a61a396d9fdd8f178ae734a12000200000000000000000001',
    '0x04c34cbe8dbe95188bce65808e5ba1b44a80b315000200000000000000000002',
    '0xf9154f85947e09efec7aefe0de55bbff796854d7000000000000000000000003',
    '0xebff6ef6a2f53b2ec29f47f9ccedcb5e6e7978cb000000000000000000000005',
    '0x412fd1939df8716b616b603ee582074c1e40bb6d000200000000000000000007',
  ],
  IncludedPoolTypes: ['Weighted', 'ComposableStable', 'FX'],
  Stable: {
    AllowList: [
      // berachain mainnet doesn't require allow listing of pools
      // instead we tag pools with 'Curated' and filter the UI
      // to show Curated pools only by default
    ],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [],
  },
  Factories: {
    [berachain.WeightedPoolFactory]: 'weightedPool',
    [berachain.ComposableStablePoolFactory]: 'composableStablePool',
    // append fxPoolFactories to the factories
    ...Object.fromEntries(
      Object.values(fxPoolFactories).map(factory => [factory, 'fx'])
    ),
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [
      // deprecated: burrbear is now using rewards subgraph to determine if pool is stakeable or not
    ],
    BurrMultiplier: {
      '0xd10e65a5f8ca6f835f2b1832e37cf150fb955f23000000000000000000000004': 2,
      '0x567f32e86be3e3963cdbc1887b5043b701f113d9000000000000000000000006': 9,
      '0xe416c064946112c1626d6700d1081a750b1b1dd7000200000000000000000008': 2000,
      '0x7ce7cb1893cfbd680cbfb9dd2a9ae6a62bde66a800000000000000000000000d': 2,
      '0xb993c9c5e1a02a454ff74fd587cf231923d2dce400020000000000000000000b': 2,
      '0xd170e25f6bcb5ace2108628c647be47d59900ade00020000000000000000000c': 0.5,
    },
    External: [
      // {
      //   // BERA:HONEY pool
      //   poolAddress: '0xb6b6e240291a4d433a94279c1a68f85e2bf24857',
      //   subgraphUrl:
      //     'https://api.goldsky.com/api/public/project_cm0keevd9v59l01w4fokhdcg9/subgraphs/hpot-algebra-core/2.0.3/gn',
      //   manageLiquidityUrl: 'https://wasabee.honeypotfinance.xyz/pool-detail/',
      // },
    ],
  },
  Metadata: {
    '0x567f32e86be3e3963cdbc1887b5043b701f113d9000000000000000000000006': {
      points: [
        {
          protocol: Protocol.Smilee,
          multiple: '1',
        },
      ],
    },
    '0xe416c064946112c1626d6700d1081a750b1b1dd7000200000000000000000008': {
      points: [
        {
          protocol: Protocol.Smilee,
          multiple: '1',
        },
      ],
    },
  },
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [], // may contain pool ids with security issues
  NewVersionAvailable: {},
  Deprecated: {}, // for pool migration?
  GaugeMigration: {},
  BrandedRedirect: {},
  ExitViaInternalBalance: [],
  Issues: {},
  Curated: [
    '0xd10e65a5f8ca6f835f2b1832e37cf150fb955f23000000000000000000000004',
    '0x567f32e86be3e3963cdbc1887b5043b701f113d9000000000000000000000006',
    '0xe416c064946112c1626d6700d1081a750b1b1dd7000200000000000000000008',
    '0xd170e25f6bcb5ace2108628c647be47d59900ade00020000000000000000000c',
    '0x7ce7cb1893cfbd680cbfb9dd2a9ae6a62bde66a800000000000000000000000d',
  ],
  FXPoolFactories: fxPoolFactories,
};

export default pools;
