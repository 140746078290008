import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  Popular: {
    Symbols: ['XSGD', 'USDC'],
  },
  InitialSwapTokens: {
    input: '0xFCBD14DC51f0A4d49d5E53C2E0950e0bC26d0Dce', // HONEY
    output: '0x1ce0a25d13ce4d52071ae7e02cf1f6606f4c79d3', // NECT
  },
  Addresses: {
    nativeAsset: '0x0000000000000000000000000000000000000000', // BERA
    wNativeAsset: '0x6969696969696969696969696969696969696969', // WBERA
    WETH: '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
    BAL: '0x28e0e3B9817012b356119dF9e217c25932D609c2',
    NECT: '0x1cE0a25D13CE4d52071aE7e02Cf1F6606F4C79d3',
    gBERA: '0x3b3dd22625128Ff1548110f9B7Bc702F540668e2',
  },
  Permissionless: {
    baseTokens: [
      {
        token: '0x1cE0a25D13CE4d52071aE7e02Cf1F6606F4C79d3', // NECT
        oracle: '0x48623292bD8293b747571934379B9D3E5423DBB6', // NECT/USD 1:1 oracle
      },
    ],
    quoteTokens: [
      {
        token: '0x549943e04f40284185054145c6E4e9568C1D3241', // USDC
        oracle: '0x9f555A3115C2Da9574c84C4Dfb1617193aA7AFE2', // USDC/USD 1:1 oracle
      },
    ],
  },
};

export default tokens;
