import * as berachain from '@/assets/data/contracts/berachain.json';
import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: berachain.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: berachain.Authorizer,
  vault: berachain.Vault,
  weightedPoolFactory: berachain.WeightedPoolFactory,
  stablePoolFactory: berachain.StablePoolFactory,
  composableStablePoolFactory: berachain.ComposableStablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: berachain.BalancerHelpers,
  batchRelayer: berachain.BalancerRelayer,
  veBAL: '',
  gaugeController: '',
  gaugeFactory: '',
  balancerMinter: '',
  tokenAdmin: '',
  veDelegationProxy: berachain.VotingEscrowDelegationProxy,
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  omniVotingEscrow: '',
  claimSubmission: '',
};

export default contracts;
